

import React from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select'
import { storagegkTest } from "./../firebase";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import axios from 'axios'

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

class KurarayUpload extends React.Component {
  state = {
    options: [
      { value: 'LEAD', label: 'Dental Practice Contacts' },
      { value: 'LAB', label: 'Lab Technecian Contacts' },
      { value: 'DEALER', label: 'Dealer Contacts' },
    ],
    labAccounts: [
    ],
    type: '',
    labA: '',
    csv: [],
    fileName: '',
    account_type: 'Sales',
    accountType: [
      { value: 'Sales', label: 'Sales' },
      { value: 'Marketing', label: 'Marketing' }
    ],
    location: window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent("location").replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"),
    apiKey: '',
    csvUrl: ''
  }
  componentDidMount() {

    axios.get(`https://rest.gohighlevel.com/v1/locations/${this.state.location}`, {
      headers: {
        Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55X2lkIjoiU202N2E2QTI2RHlLbWZVS1NtMngiLCJ2ZXJzaW9uIjoxLCJpYXQiOjE2MzUxNTc1NTQyNDUsInN1YiI6ImdkeFpiaEgyUnA0T0hGWHkxcmZBIn0.X04JbvErsZRYBlKO5LHVibitPrO-PdDz7cB73i2D66Q'
      }
    }).then(async location => {
      this.setState({
        apiKey: location.data.apiKey
      })
    })
    axios.post('https://sales.agileads.co/salesforce-lab-accounts', { location: this.state.location }).then(res => {
      res.data.records.forEach(record => {
        var o = {}
        o['value'] = record.Id
        o['label'] = record.Name
        this.setState({
          labAccounts: [...this.state.labAccounts, o]
        })
      })
    })
    if (this.state.location === 'RRUfwb36E3adDkhFDNDa') {
      this.setState({
        type: 'LEAD'
      })
    } else {
      this.setState({
        type: 'LAB'
      })
    }

  }
  handleCSV = (e) => {
    this.setState({ fileName: e.target.files[0] });
    const csv = e.target.files[0]
    if (csv) {

      var filename = `${this.state.location}-${csv.name.replace(/-/g, '')}-${makeid(6)}`

      const uploadTask = storagegkTest
        .ref(`/contact-upload/${this.state.location}/${filename}.csv`)
        .put(csv);
      uploadTask.on(
        "state_changed",
        (snapShot) => { },
        (err) => {
          console.log(err);
        },
        () => {
          storagegkTest
            .ref(`contact-upload/${this.state.location}`)
            .child(`${filename}.csv`)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              this.setState({
                csvUrl: fireBaseUrl
              })

            });
        }
      );
    }

  };

  submitCSV = (e) => {
    e.preventDefault();
    console.log(this.state)


    if (this.state.type === 'LAB' && this.state.csvUrl) {
      axios.post('https://sales.agileads.co/contact-upload-lab', { api: this.state.apiKey, csvUrl: this.state.csvUrl, labId: this.state.labA }).then(res => {
        var p = document.createElement('p')
        p.innerText = `File Uploaded Successfully`
        var added = document.getElementById('csv-uploaded')
        added.appendChild(p)
        window.alert("Uploaded Successfully")
      })
    } else if (this.state.type === 'LEAD' && this.state.csvUrl) {
      axios.post('https://sales.agileads.co/contact-upload-lead', { api: this.state.apiKey, csvUrl: this.state.csvUrl, account_type: this.state.account_type }).then(res => {
        var p = document.createElement('p')
        p.innerText = `File Uploaded Successfully`
        var added = document.getElementById('csv-uploaded')
        added.appendChild(p)
        window.alert("Uploaded Successfully")
      })
    }
  }
  handleChange = (e) => {
    this.setState({
      type: e.value
    })
    console.log("test", this.e.value);
  }
  handleChangeAccountType = (e) => {
    this.setState({
      account_type: e.value
    })

  }
  handleChangeLabAccount = (e) => {
    this.setState({
      labA: e.value
    })

  }
  render() {
    const fileName = this.state
    console.log("thist", fileName.fileName);
    console.log("thist", fileName.fileName.name);
    return (
      <div>
        <div className="banner-bg33" style={{ height: '85vh' }}>
          <Container>

            <div className="form-bg contact-upload p-3">
              <h2>Upload Contacts</h2>
              <p>File Type: .csv*</p>
              <p>Please upload your contacts list with supported file type and get start now!</p>
              <p>All .csv file types are supported.</p>
              <div className="uploadCsv" style={{ width: '100%', maxWidth: '290px' }}>
                <div className="file-upload">
                  <form onSubmit={this.submitCSV}>
                    <div id="csv-upload" style={{ width: '100%' }}>
                      <input
                        type="file"
                        accept=".csv"
                        className="hidden form-control-file file-upload-input"
                        style={{ height: "100%" }}
                        id="doc-images"
                        onChange={this.handleCSV}
                      />

                      <label htmlFor="doc-images" style={{ margin: 'auto', width: '92%' }} className=" pd_0">
                        <div className="choose-file">
                          <h3 style={{ borderRadius: '5px' }}>Browse File <i class="fa fa-upload" aria-hidden="true"></i>
                          </h3>
                        </div>
                      </label>
                      <label htmlFor="file" style={{ marginTop: '1px', color: '#333', textAlign: 'center', fontSize: '12px' }}>{fileName.fileName.name}</label>
                    </div>

                    <div id="csv-upload-assignee" style={{ margin: '16px' }}>
                      {
                        this.state.location === 'RRUfwb36E3adDkhFDNDa' ?
                          <>
                            <Select
                              defaultValue={{ label: "Sales", value: 'Sales' }}
                              options={this.state.accountType} onChange={this.handleChangeAccountType} />
                          </>
                          :
                          <>

                            <Select
                              defaultValue={{ label: "Select Type", value: 'Select Lab Account' }}
                              options={this.state.labAccounts} onChange={this.handleChangeLabAccount} />
                          </>
                      }
                    </div>

                    <div id="csv-uploaded">

                    </div>
                    <button
                      style={{ zIndex: '0' }}
                      type="submit"
                      className="btn btn-primary step-btn company"
                    >
                      Upload
                    </button>
                  </form>
                </div>
              </div>
            </div>

          </Container>
        </div>


      </div>
    );
  }
}

export default KurarayUpload;