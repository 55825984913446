
// import * as firebase from 'firebase';
import 'firebase/firestore';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage';


var firebaseConfig = {
  apiKey: "AIzaSyAjlwpQpstEWkyWf9OWeq4Gtiyk2CeSioc",
  authDomain: "socialhi-5.firebaseapp.com",
  databaseURL: "https://socialhi-5.firebaseio.com",
  projectId: "socialhi-5",
  storageBucket: "socialhi-5.appspot.com",
  messagingSenderId: "296318370685",
  appId: "1:296318370685:web:8ea5abdd44ef196f84c214"
};
// Initialize Firebase

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage()
const db = firebase.firestore();



var firebaseConfigKuraray = {
  apiKey: "AIzaSyDmCs6J06VRaOGV43qiJUvVHBVR_IQW83c",
  authDomain: "agileads-backend.firebaseapp.com",
  projectId: "agileads-backend",
  storageBucket: "agileads-backend.appspot.com",
  messagingSenderId: "539040601338",
  databaseURL: 'agileads-backend.firebaseapp.com',
  appId: "1:539040601338:web:dc7588f7509f2bf226e543"
};

var kuraray = firebase.initializeApp(firebaseConfigKuraray, "agileads-backend");
const storageKuraray = kuraray.storage()
const dbKuraray = kuraray.firestore();


var firebaseConfiggkTest = {
  apiKey: "AIzaSyCgbP8gturrL5shJRlEtfG735aUZE8_foM",
  authDomain: "gktest-9a6cb.firebaseapp.com",
  databaseURL: "https://gktest-9a6cb.firebaseio.com",
  projectId: "gktest-9a6cb",
  storageBucket: "gktest-9a6cb.appspot.com",
  messagingSenderId: "399736700120",
  appId: "1:399736700120:web:8f759f9e2843686fa1ebad",
  measurementId: "G-QHJD9EJQ20"
};

var gkTest = firebase.initializeApp(firebaseConfiggkTest, "gk-test");
const storagegkTest = gkTest.storage()
const dbgkTest = gkTest.firestore();


export { storage, firebase as default, db, storageKuraray, dbKuraray, storagegkTest, dbgkTest }