import React, { Component } from 'react'
import axios from 'axios'
import Loader from "react-js-loader";
import GaugeChart from 'react-gauge-chart'
import DataTable from 'react-data-table-component';



const columns = [
    {
        name: 'Type',
        selector: row => row.Type,
        sortable: true,
    },
    {
        name: 'Description',
        selector: row => row.Description,
        sortable: true,
        minWidth: '20px'
    },
    {
        name: 'Scores',
        selector: row => row['Scores for Clause'],
        sortable: true,
        maxWidth: '10px'
    }
];

export default class SpamScore extends Component {

    state = {
        loaded: false,
        spam_result: this.props['spamResult'],
    }
    componentDidMount() {
        this.setState({
            loaded:true
        })
    }
    render() {
        return (
            <>
                {
                    this.state.loaded ? <>
                        <div className='spam-chart' style={{margin:'20px 0px'}}>
                            <div className='row' style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <div className='col-md-6'>
                                <GaugeChart
                            id="gauge-chart1"
                            percent={parseFloat(this.state.spam_result.score.toFixed(2)) / 5}
                            hideText={true}
                            textColor="transparent"
                            needleBaseColor="#03a9f4"
                            arcPadding={0.015}
                            cornerRadius={0}
                            arcWidth={0.19}
                            needleColor="#03a9f4"
                            arcsLength={[0.3, 0.2, 0.3]}
                            colors={['#5BE12C', '#F5CD19', '#EA4228']}
                        />
                                </div>
                                <div className='col-md-6'>
                                    <p className="nospam">
                                        Email Classification : <span>{this.state.spam_result.class} </span><br/>
                                        
                                    </p>
                                    <p className="spamscore">
                                    Spam Score : <span>{parseFloat(this.state.spam_result.score.toFixed(2))} </span>
                                    </p>
                                    <p style={{color: '#222', fontSize:'10px'}}>*Score greater than 2.5 considered as spam.</p>
                                </div>
                                </div>
                            </div>
                                <DataTable
                            // title="Contacts"
                            columns={columns}
                            data={this.state.spam_result.reasons}
                        />
                    </> : <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <Loader type="hourglass" bgColor={"#03a9f4"} color={'#03a9f4'} size={100} />
                        </div></>
                }
            </>
        )
    }
}